<template>
  <div coach-tv-playlist-group>
    <section-head :extra="subTitle" :title="title" class="top-wrap">
      <div class="btn-wrap">
        <button v-if="playlistType !== 'recommend'" :class="{'has-cursor': enableCursorBtn}" class="view-all-btn" @click="viewAllBtnHandler">
          <span
            class="btn-text">{{ $t('coaching.BA_main_overview') }}
          </span>
        </button>
        <button v-if="enableCursorBtn" :class="{disable: prevBtnDisable}" class="arrow-btn left"
          @click="prevBtnHandler">
          <img
            class="btn-icon left"
            src="/img/icon/icon-arrow-down-dark.svg" />
        </button>
        <button v-if="enableCursorBtn" :class="{disable: nextBtnDisable}" class="arrow-btn right"
          @click="nextBtnHandler">
          <img
            class="btn-icon right"
            src="/img/icon/icon-arrow-down-dark.svg" />
        </button>
      </div>
    </section-head>
    <div class="content-wrap">
      <p class="description">{{ description }}</p>
      <CoachTvPlaylist ref="playlist" :contentList="contentList" :playlistType="playlistType"
        :slidesPerView="slidesPerView" @click="coachTvCardClickHandler" />
    </div>
  </div>
</template>

<script>
import '@/utils/polyfillUtils';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import { mapState } from 'vuex';
import CoachTvPlaylist from '@/views/components/coaching/coachTv/CoachTvPlaylist.vue';
import SectionHead from '@/views/components/coaching/SectionHead.vue';

export default {
  name: 'CoachTvPlaylistGroup',
  components: {
    CoachTvPlaylist, SectionHead,
  },
  props: {
    // main, latest, recommend, group
    playlistType: {
      type: String,
      default: '',
      required: true,
    },
    tvContentGroupId: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    /** @type {CoachTvContentList} */
    contentList: [],
    cursor: 0,
    hasNext: true,
    totalCount: 0,
    slideIndex: 0,
  }),
  watch: {
    matchedMedia() {
      this.$nextTick(() => {
        const slider = _get(this, '$refs.playlist.$refs.slider');
        this.slideIndex = slider.now;
      });
    },
  },
  computed: {
    ...mapState('browser', ['matchedMedia']),
    isMobile() {
      return this.matchedMedia?.charAt(0) === 'M';
    },
    isDesktop() {
      return this.matchedMedia?.charAt(0) === 'D';
    },
    isMainType() {
      return this.playlistType === 'main';
    },
    _query() {
      const _q = {};
      switch (this.playlistType) {
        case 'main': {
          _q.isRecommend = true;
          break;
        }
        case 'recommend': {
          _q.isRecommend = true;
          break;
        }
        case 'latest': {
          _q.order = 'contentCreatedDatetime desc';
          break;
        }
        case 'group': {
          _q.order = 'contentCreatedDatetime desc';
          _q.tvContentGroupId = this.tvContentGroupId;
          break;
        }
        default:
          return;
      }

      _q.cursor = this.cursor;
      _q.size = this.isMainType ? 4 : 3;
      _q.tvContentGroupId = this.tvContentGroupId;

      return _q;
    },
    maxIndex() {
      const { totalCount } = this;
      if (totalCount <= 0) return 0;
      if (this.isMobile) return totalCount - 1;
      return Math.ceil(totalCount / 3) - 1;
    },
    prevBtnDisable() {
      return this.slideIndex <= 0;
    },
    nextBtnDisable() {
      return this.slideIndex >= this.maxIndex;
    },
    slidesPerView() {
      if (this.isDesktop) {
        return 3;
      } return 1;
    },
    enableCursorBtn() {
      return !(this.isMainType && !this.isMobile);
    },
  },
  methods: {
    async _getCoachTvContentList() {
      if (!this.hasNext) return;

      const result = await this.$services.coaching.getCoachTvContentList(this._query);
      const { items, hasNext, totalCount, nextCursor } = result;

      this.contentList = [...this.contentList, ...items];
      this.cursor = nextCursor;
      this.hasNext = hasNext;
      this.totalCount = totalCount;
    },
    coachTvCardClickHandler(coachTvItem) {
      const tvContentId = _get(coachTvItem, 'tvContentId');
      const tvContentGroupId = _get(coachTvItem, 'tvContentGroup.tvContentGroupId');
      this.$router.push({ name: 'CoachTvViewer', params: { gameId: 'lol', tvContentId, tvContentGroupId } });
    },
    viewAllBtnHandler() {
      if (this.playlistType === 'main') {
        this.$router.push({ name: 'CoachTvViewAll', params: { gameId: 'lol' } });
        return;
      }

      if (this.playlistType === 'latest') {
        this.$router.push({ name: 'CoachTvViewFilter', params: { gameId: 'lol' } });
      }

      if (this.playlistType === 'group') {
        const playlist = String(this.title).replaceAll(' ', '%20');
        this.$router.push({ name: 'CoachTvViewFilter', params: { gameId: 'lol' }, query: { playlist } });
      }
    },
    prevBtnHandler() {
      this.slideIndex -= 1;
      const { playlist } = this.$refs;
      if (!playlist) return;
      playlist?.prev();
    },
    nextBtnHandler: _debounce(async function () {
        await this._getCoachTvContentList();
        this.slideIndex += 1;
        this.$nextTick(() => {
          const { playlist } = this.$refs;
          playlist?.next();
        });
      }, 200, { trailing: true }),
  },
  mounted() {
    this._getCoachTvContentList();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[coach-tv-playlist-group] { .flex; .flex-wrap; flex-direction: column; column-gap: 16px;
  .content-wrap { .flex; flex-direction: column; .w(100%); .p(0);
    .description {.w(fit-content); .mt(10); .mb(20); .bgc(#faf2ff); .p(2, 8); .fs(13);}
  }
  .top-wrap { .mb(20);
    [section-title] {line-break: anywhere}
  }
  .btn-wrap { .flex;
    .view-all-btn { .h(40px); .p(0 8px); .bgc(#000); .c(#fff); .br(12px);
      &.has-cursor { .mr(16px); }
    }
    .arrow-btn {.w(40);.h(40px);.p(5);.br(15%);.bgc(#ebebf0); border: unset;
      .btn-icon {.w(100%);.h(100%);.o(0.4);border: unset;border: 0;
        &.left {transform: rotate(90deg);}
        &.right {transform: rotate(270deg);}
      }
      &.disable { .bgc(#ccc); pointer-events: none; }
      &.right { .ml(6px);}
      @{hover-press} {
        .btn-icon {
        }
      }
    }
  }

  @media (@tp-up) {
    .top-wrap {
    }
  }

}
</style>
