<template>
  <div coach-tv-view-all>
    <div class="top-wrap">
      <button class="btn-prev" @click="prevBtnHandler">
        <img src="/img/icon/icon-arrow-down-dark.svg">
      </button>
    </div>
    <div class="content-wrap">
      <div class="content-holder">
        <div class="search-field">
          <TextInput v-model="searchText" @keydown.native.enter="searchBtnHandler" :placeholder="$t('coaching.MC_PLACEHOLDER_SEARCH_TV')" class="search-text" />
          <button class="search-btn" @click="searchBtnHandler">
            <img class="search-icon" src="/img/icon/icon-search-gray.svg" />
          </button>
        </div>
        <div class="playlist-wrap">
          <div class="playlist-wrap-inner">
            <CoachTvPlaylistGroup :description="$t('coaching.MS_HOT_TV')" :title="$t('coaching.MT_HOT_TV')" class="test" playlistType="recommend"></CoachTvPlaylistGroup>
            <CoachTvPlaylistGroup :description="$t('coaching.MS_NEW_TV')" :title="$t('coaching.MT_NEW_TV')" class="test" playlistType="latest"></CoachTvPlaylistGroup>
            <CoachTvPlaylistGroup v-for="(item,i) in playlist" :key="i" :description="item.description" :title="item.title" :tvContentGroupId="item.tvContentGroupId" class="test" playlistType="group"></CoachTvPlaylistGroup>
          </div>
          <div v-if="hasNext" class="more" @click="fetchMorePlaylist">
            <div class="divider">
              <button @click="$emit('more')">
                <span>{{ $t('coaching.BA_qlist_more') }}</span>
                <img src="/img/icon/icon-arrow-down-dark.svg">
              </button>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="right-side">
        <DiscordButton />
        <CoachList />
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@shared/components/common/input/TextInput.vue';
import CoachList from '@/views/components/coaching/coachList/CoachList.vue';
import DiscordButton from '@/views/components/coaching/DiscordButton.vue';
import CoachTvPlaylistGroup from '@/views/components/coaching/coachTv/CoachTvPlaylistGroup.vue';

export default {
  name: 'CoachTvViewAll',
  components: {
    CoachTvPlaylistGroup, DiscordButton, CoachList, TextInput,
  },
  data: () => ({
    offset: 0,
    hasNext: false,
    playlist: [],
    searchText: '',
  }),
  metaInfo() {
    return {
      title: this.$t('meta.coachTv.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.coachTv.title') },
        { vmid: 'description', content: this.$t('meta.coachTv.description') },
        { vmid: 'url', content: this.$t('meta.coachTv.url') },
        { vmid: 'keywords', content: this.$t('meta.coachTv.keywords') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  methods: {
    async fetchMorePlaylist() {
      const _playlist = this.playlist.slice();
      const result = await this.$services.coaching.getCoachTvPlaylist({ offset: this.offset, size: 3 });
      const offset = result?.nextOffset;
      const hasNext = result?.hasNext;
      const items = result?.items;

      this.offset = offset;
      this.hasNext = hasNext;
      this.playlist = [..._playlist, ...items];
    },
    prevBtnHandler() {
      this.$router.push({ name: 'CoachHome' });
    },
    searchBtnHandler() {
      this.$router.push({ name: 'CoachTvViewFilter', query: { title: this.searchText } });
    },
  },
  async asyncData({ services }) {
    const result = await services.coaching.getCoachTvPlaylist({ offset: 0, size: 1 });
    const offset = result?.nextOffset;
    const hasNext = result?.hasNext;
    const items = result?.items;

    return { offset, hasNext, playlist: items };
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-tv-view-all] {.bgc(#fff); .pb(40);
  .top-wrap { .h(156); .max-w(1260); .m(0, auto); .pt(80); .pr(20); .pl(20);
    .btn-prev { .flex; .w(40); .h(40); transform: rotate(90deg); .bgc(#ebebf0); .br(12);
      &:hover {
        > img { .o(0.8);}
      }
      > img { .m(auto); .o(0.4);}
    }
  }

  .content-wrap { .flex; .m(0, auto); .pr(20); .pl(20);
    .content-holder {.w(100%); .mr(56);
      .search-field { .flex; .mb(40);
        .search-text {.w(100%);}
        .search-btn {.w(48); .h(48); .bgc(#ebebf0); .br(12); .ml(8);}
      }
      .playlist-wrap {
        .playlist-wrap-inner {
          [coach-tv-playlist-group] { .pb(40); .mb(40); border-bottom: solid 1px #dce0e3;
            &:last-of-type {border-bottom: none;}
            .top-wrap {.p(0); .h(unset);}
            .content-wrap {.p(0);}
          }
        }
        .more { .mt(40);
          .divider { .rel;
            > button { .flex; .items-center; .h(40); .abs; .p(0, 8); .bgc(#ebebf0); .c(#000); .br(12); border: none; .z(1); right: 50%; transform: translate(50%, -50%);
              > img {.o(0.4)}
            }
            > hr { .w(100%); .h(2); .bgc(#ebebf0);}
          }
        }
      }
    }
    .right-side {.ml(auto); .hide;}
  }

  @media (@tp-up) {
    .top-wrap { .pr(58); .pl(58); }
    .content-wrap {.pr(58); .pl(58);}
  }

  @media (@ds-up) {
    .top-wrap {.pr(0); .pl(0);}
    .content-wrap { .max-w(1260); .pr(0); .pl(0);
      .content-holder { .max-w(848); .w(calc(100% - 432px)); }
      .right-side {display: unset;}
    }
  }
}
</style>
